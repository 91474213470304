import {
  Instance_default
} from "./chunk-YFNULCPH.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/InvesterContact/InvesterContactRoute.ts
var InvesterContactRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    /**
     *
     * @param data
     */
    this.createInvesterContactRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "invester_contact"
      }),
      data
    );
  }
};
var InvesterContactRoute_default = InvesterContactRoute;

export {
  InvesterContactRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
