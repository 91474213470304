import {
  Instance_default
} from "./chunk-YFNULCPH.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/ContentTheme/ContentThemeRoute.ts
var ContentThemeRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getContentThemeItemRequest = (id) => Instance_default.get(
      this.createRequestURL({
        option: "content_theme",
        id
      })
    );
    this.getContentThemesRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "content_theme",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    /**
     *
     * @param data
     */
    this.createContentThemeRequest = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "content_theme"
        }),
        data
      );
    };
    /**
     *
     * @param data
     */
    this.updateContentThemeRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "content_theme"
      }),
      data
    );
    /**
     *
     * @param id
     */
    this.deleteContentThemeRequest = (id) => {
      let ids = null;
      if (typeof id === "string") {
        ids = id.split(",");
      }
      if (ids === null) {
        return Instance_default.delete(
          this.createRequestURL({
            option: "content_theme",
            id
          })
        );
      } else {
        return Instance_default.post(
          this.createRequestURL({
            option: "content_theme",
            task: "deleteAll"
          }),
          {
            id
          }
        );
      }
    };
    this.getContentThemeByDesignIdRequest = (designId) => Instance_default.get(
      this.createRequestURL({
        option: "content_theme",
        task: "getContentThemeByDesignId",
        design_id: designId
      })
    );
  }
};
var ContentThemeRoute_default = ContentThemeRoute;

export {
  ContentThemeRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
